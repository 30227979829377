.machine-info {
    padding: 20px 24px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #035B86;
    color: #fff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.machine-info h1 {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
}

.machine-info .machine-info-model,
.machine-info .machine-info-class {
    font-style: 14px;
    margin: 0;
    margin-top: 5px;
    color: #fff;
    font-weight: 400;
}

.machine-info .machine-info-footer {
    width: 100%;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
}

.machine-info .machine-info-footer label {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 3px;
}

.machine-info .machine-info-footer p {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}

.machine-info .machine-info-footer .machine-info-footer-item {
    display: flex;
    flex-direction: column;
}

.machine-info .machine-info-footer .machine-info-footer-item:last-child label {
    text-align: right;
    line-height: 1.4;
}

/*  */

.machine-service {
    padding: 20px 24px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #1B89C7;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.machine-service h3 {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin: 0;
    margin-bottom: 29px;
}

/*  */

.catalog {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 264px; */
    height: 120px;
    padding: 20px;
    background-color: #29ABE2;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.noty {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    width: 100%;
    height: 120px;
    padding: 20px;
    background-color: #F5F6FA;
    border-radius: 4px;
}

.noty h3 {
    margin: 0;
    font-size: 16px;
}

.obrash-btn {
    display: inline-block;
    background-color: none;
    border: 1px solid #29ABE2;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}

.catalog-btn-wrapper {
    display: flex;
    margin-top: auto;
}

.catalog-btn-wrapper a {
    margin-right: 12px;
}

.catalog h3 {
    font-size: 16px;
    color: #fff;
    margin: 0;
}

/*  */

.machine-owner-wrapper {
    width: 100%;
    height: 100%;
    background-color: #F5F6FA;
    border-radius: 4px;
    padding: 20px 24px;
}

.machine-owner-wrapper h3 {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    padding: 0;
    margin: 0;
}

.machine-owner-wrapper p {
    font-size: 13px;
    font-weight: 400;
    color: #333;
    padding: 0;
    margin: 0;
}

.machine-owner-wrapper span {
    font-size: 14px;
    font-weight: 700;
    color: #333;
}

.machine-owner-wrapper .owner {
    margin-top: 30px;
}

.machine-owner-wrapper .owner-data {
    margin-top: 30px;
}

.machine-owner-wrapper .owner-data p {
    line-height: 20px;
}

.machine-owner-wrapper .btn-btn-round {
    margin-top: 11px;
}

/*  */

.items-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
    column-gap: 24px;
}

.card-item {
    background-color: #F5F6FA;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.card-item p {
    margin: 0;
    text-align: center;
    margin-top: 13px;
    font-style: 13px;
    color: #333;
    font-weight: 600;
}

.card-item {
    text-decoration: none;
    color: none;
}

.card-item img {
    width: 60px;
    height: 60px;
    display: block;
    margin: 0 auto;
}

/*  */

.machine-stat {
    display: flex;
    padding: 20px 28px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F5F6FA;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.machine-stat h3 {
    font-size: 16px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    margin-bottom: 29px;
    text-align: center;
}

.machine-stat img {
    width: 100%;
}