#fuel {
    width: 100%;
    height: 100%;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    text-align: center;
    padding-top: 10px;
    cursor: pointer;
}

#fuel p {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
}

.svgText {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
}

.small {
    font-size: 1.5px;
}

.big {
    font-size: 4px;
}