#work {
    width: 100%;
    height: 100%;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 7px;
    padding-bottom: 13px;
}

#work p {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 700;
    margin: 6px 0;
}

#work span {
    font-size: 12px;
    color: #787878;
    margin-bottom: 10px;
    display: block;
}

.svgText {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
}

.small {
    font-size: 1.5px;
}

.big {
    font-size: 4px;
}

#work .desc {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

#work .desc p {
    font-size: 12px;
    color: #333333;
    font-weight: 400;
    margin: 0;
}

#work .desc span {
    width: 12px;
    height: 12px;
    border-radius: 25px;
    background-color: #C4C4C4;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}

#work .desc div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#work .mid span {
    background-color: #29ABE2;
}

#work .max span {
    background-color: #72C900;
}