@font-face {
    font-family: 'Proxima Nova';
    src: url('assets/fonts/ProximaNova-BlackIt.eot');
    src: local('Proxima Nova Black Italic'), local('ProximaNova-BlackIt'),
        url('assets/fonts/ProximaNova-BlackIt.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/ProximaNova-BlackIt.woff2') format('woff2'),
        url('assets/fonts/ProximaNova-BlackIt.woff') format('woff'),
        url('assets/fonts/ProximaNova-BlackIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('assets/fonts/ProximaNova-BoldIt.eot');
    src: local('Proxima Nova Bold Italic'), local('ProximaNova-BoldIt'),
        url('assets/fonts/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/ProximaNova-BoldIt.woff2') format('woff2'),
        url('assets/fonts/ProximaNova-BoldIt.woff') format('woff'),
        url('assets/fonts/ProximaNova-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('assets/fonts/ProximaNova-ExtraBold.eot');
    src: local('Proxima Nova ExtraBold'), local('ProximaNova-ExtraBold'),
        url('assets/fonts/ProximaNova-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/ProximaNova-ExtraBold.woff2') format('woff2'),
        url('assets/fonts/ProximaNova-ExtraBold.woff') format('woff'),
        url('assets/fonts/ProximaNova-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('assets/fonts/ProximaNova-Regular.eot');
    src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
        url('assets/fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('assets/fonts/ProximaNova-Regular.woff') format('woff'),
        url('assets/fonts/ProximaNova-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('assets/fonts/ProximaNova-Light.eot');
    src: local('Proxima Nova Light'), local('ProximaNova-Light'),
        url('assets/fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/ProximaNova-Light.woff2') format('woff2'),
        url('assets/fonts/ProximaNova-Light.woff') format('woff'),
        url('assets/fonts/ProximaNova-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('assets/fonts/ProximaNova-Black.eot');
    src: local('Proxima Nova Black'), local('ProximaNova-Black'),
        url('assets/fonts/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/ProximaNova-Black.woff2') format('woff2'),
        url('assets/fonts/ProximaNova-Black.woff') format('woff'),
        url('assets/fonts/ProximaNova-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('assets/fonts/ProximaNova-Bold.eot');
    src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
        url('assets/fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/ProximaNova-Bold.woff2') format('woff2'),
        url('assets/fonts/ProximaNova-Bold.woff') format('woff'),
        url('assets/fonts/ProximaNova-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



body {
    margin: 0;
    font-family: 'Proxima Nova', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.body {
    background-color: #5955b3;
    box-sizing: border-box;
}

body * {
    box-sizing: border-box;
}

.cssload-container {
    width: 30px;
    height: 30px;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.cssload-speeding-wheel {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    border: 2px solid #5955b3;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    -webkit-animation: cssload-spin .7s infinite ease-out;
    animation: cssload-spin .7s infinite ease-out;
}

.Toastify__toast {
    z-index: 9999;
}

@-webkit-keyframes cssload-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes cssload-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        transform: rotate(360deg)
    }
}