.table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}
.table * {
    cursor: default;
}
.table thead th {
    font-size: 13px;
    font-weight: 700;
    padding: 0 19px 15px;
    text-align: left;
}
.table tbody td {
    font-size: 14px;
    font-weight: 400;
    padding: 15px 19px;
    text-align: left;
}
.table tbody .td.to span {
    margin-right: 10px;
}
.table tbody .td.bad span {
    background: #DB1221;
}
.table tbody tr:hover {
    background: #F5F6FA;
}
.table tbody tr {
    border-bottom: 1px solid #F5F6FA;
}

.table .span-link {
  cursor: pointer;
  color: #3498db;
}
