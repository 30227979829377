.coords {
    shape-rendering: crispEdges;
}

.coordsText {
    font-family: "Proxima Nova Rg", sans-serif;
    font-size: 12px;
    color: #3c3c3c;
}

.coordsText.bold {
    font-weight: bold;
}

.svgT {
    font-family: "Proxima Nova Rg", sans-serif;
    font-size: 14px;
}

.svgText {
    font-family: "Proxima Nova Rg", sans-serif;
    font-weight: bold;
}