.message-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
}
.message-wrap.my {
  justify-content: end;
  padding-right: 10px;
}
.message-wrap.some {
  justify-content: start;
  padding-left: 10px;
}
.message-item {
  display: block;
  width: 380px;
  text-align: left;
  padding: 20px 30px;
  position: relative;
}
.message-item .bottom {
  display: flex;
  justify-content: space-between;
}
.message-wrap.my::after {
  content: "";
  position: absolute;
  right: -10px;
  top: 0;
  border: 10px solid transparent;
  border-left: 10px solid #48a6e1;
  border-top: 10px solid #48a6e1;
}

.message-wrap.some::after {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  border: 10px solid transparent;
  border-top: 10px solid #f3f3f3;
  border-right: 10px solid #f3f3f3;
}
.message-wrap.my .message-item {
  border-radius: 6px 0px 6px 6px;
  background-color: #48a6e1;
  color: #fff;
}
.message-wrap.some .message-item {
  border-radius: 0 6px 6px 6px;
  background-color: #f3f3f3;
  color: #000;
}
.message-item .time {
  font-size: 0.9em;
}
.message-wrap.some .time {
  color: #999999;
}

.message-item .message {
  margin-bottom: 20px;
}

.message-nots {
  display: block;
  max-width: 380px;
  font-size: 0.9em;
  text-align: center;
  background-color: #c4c4c4;
  color: #fff;
  padding: 5px 20px;
  border-radius: 10px;
}
