.wrapper {}

.cordHid {
    font-size: 12px;
    display: none;
}

.cordShow:hover .cordHid {
    display: block;
}

.fuelGrafWrapper {
    width: 289px;
}

.fuelWrapper {
    display: flex;
    margin-bottom: 44px;
}

.fuelCard {
    padding: 22px 24px;
    background-color: #F5F6FA;
    margin-left: 24px;
    width: 448px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

/**/
.divTable {
    display: table;
    width: 100%;
}

.divTableRow {
    display: table-row;
}

.divTableHeading {
    display: table-header-group;
}

.divTableCell,
.divTableHead {
    display: table-cell;
}

.divTableBody {
    display: table-row-group;
}

.whiteTable .divTableBody .divTableCell {
    color: #333333;
    font-size: 14px;
    padding: 15px 0;
}

.whiteTable .divTableRow:last-child .divTableCell {
    border-bottom: 1px solid #CCCCCC;
    font-weight: 700;
}

.fuelCard h3 {
    font-size: 16px;
    color: #000;
    font-weight: 700;
}

.smTable .divTableBody .divTableCell {
    color: #333333;
    font-size: 14px;
    padding: 15px 0;
    border-bottom: 1px solid #EEEEEE;
}

.fuelCard span {
    font-size: 14px;
    margin-top: 15px;
    font-weight: 400;
    color: #6c6c6c;
    display: block;
}

.dFlex {
    display: flex;
}

.fuelCard span:last-child {
    margin-left: 42px;
    color: #F15A24;
}

.notData {
    font-size: 14px;
    color: #CCCCCC;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notDataHeight {
    font-size: 14px;
    color: #CCCCCC;
    height: 309px;
    display: flex;
    align-items: center;
    justify-content: center;
}