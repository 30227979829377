.preloader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6000;
    background: #fff;
}

.cssload-container {
    width: 49px;
    height: 49px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.cssload-speeding-wheel {
    width: 49px;
    height: 49px;
    margin: 0 auto;
    border: 2px solid #1976d2;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    -webkit-animation: cssload-spin .7s infinite ease-out;
    animation: cssload-spin .7s infinite ease-out;
}

@-webkit-keyframes cssload-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes cssload-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        transform: rotate(360deg)
    }
}