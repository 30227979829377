.state_wrapper {
    display: flex;
}

.state_work {
    background: #035B86;
    margin-right: 24px;
    flex: 1;
    padding: 22px 24px 24px 24px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.state_fuel {
    background: #F5F6FA;
    flex: 1;
    padding: 22px 24px 24px 24px;
    max-width: 32%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.state_work_body {
    display: flex;
}

.state_work_footer {
    border-top: 1px solid #097EAF;
    padding-top: 26px;
    display: flex;
    justify-content: space-between;
}

.state_work_footer p {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}

.table {
    width: 58%;
}

.graf {
    width: calc(42% - 24px);
    border-right: 1px solid #097EAF;
    margin-right: 24px;
    margin-bottom: 15px;
}

.graf h3 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 24px;
}

.graf_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.graf_wrapper svg {
    margin-right: 35px;
}

.graf_desc div {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    color: #fff;
    font-size: 12px;
}

.graf_desc span {
    margin-right: 8px;
    width: 12px;
    height: 12px;
    border-radius: 25px;
    background-color: #C4C4C4;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}

.max span {
    background-color: #72C900;
}

.mid span {
    background-color: #29ABE2;
}

.graf_text {
    font-weight: bold;
    fill: #fff;
    font-size: 4px;
}

.event_wrapper {
    margin-top: 24px;
    width: 100%;
    background-color: #F5F6FA;
    border: 1px solid #EEEEEE;
    display: flex;
    overflow: hidden;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.map_wrapper {
    width: 58%;
    height: 442px;
}

.table_wrapper {
    width: 42%;
    height: 444px;
    overflow-y: scroll;
}

.table_wrapper p {
    margin: 0;
}

.work_wrapper {
    border: 1px solid #EEEEEE;
    margin-top: 24px;
    width: 100%;
    padding: 22px 24px;
    background-color: #F5F6FA;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.table_wrapper::-webkit-scrollbar {
    width: 8px;
}

.table_wrapper::-webkit-scrollbar-track {
    background: #EEEEEE;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.table_wrapper::-webkit-scrollbar-thumb {
    background-color: #035B86;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.blueTable .divTableBody .divTableCell {
    color: #fff;
    font-size: 14px;
    padding: 15px 0;
}

.blueTable .divTableHeading .divTableHead {
    color: #fff;
    font-size: 13px;
    padding-bottom: 11px;
}

.blueTable .divTableHeading .divTableHead:first-child {
    width: 50%;
}

.blueTable .divTableRow:last-child .divTableCell {
    border-bottom: none;
}

.blueTable .divTableCell {
    border-bottom: 1px solid #097EAF;
}

/**/
.divTable {
    display: table;
    width: 100%;
}

.divTableRow {
    display: table-row;
}

.divTableHeading {
    display: table-header-group;
}

.divTableCell,
.divTableHead {
    display: table-cell;
}

.divTableBody {
    display: table-row-group;
}

.whiteTable .divTableBody .divTableCell {
    color: #333333;
    font-size: 14px;
    padding: 15px 0;
}

.whiteTable .divTableRow:last-child .divTableCell {
    border-bottom: 1px solid #CCCCCC;
    font-weight: 700;
}

.state_fuel h3 {
    font-size: 16px;
    color: #000;
    font-weight: 700;
}

.smTable .divTableBody .divTableCell {
    color: #333333;
    font-size: 14px;
    padding: 15px 0;
    border-bottom: 1px solid #EEEEEE;
}

.state_fuel span {
    font-size: 14px;
    font-weight: 400;
    color: #6c6c6c;
    margin-top: 25px;
    display: block;
}

.notData {
    font-size: 14px;
    color: #CCCCCC;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notDataHeight {
    font-size: 14px;
    color: #CCCCCC;
    height: 309px;
    display: flex;
    align-items: center;
    justify-content: center;
}